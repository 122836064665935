import React from "react";
import { Link } from "gatsby";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import color from "~styles/color";
import { Emoji } from "emoji-mart";
import { DownOutlined } from "@ant-design/icons";

import { headerId, PREPEND_TO_SUB_HASH } from "./text-helpers";

import Footer from "~components/footer";
import { TextHeader } from "~components/header";

// formats the page title, header, main content, and right sidebar content.
// TODO: modularize.
const DEFAULT_MAX_CONTENT_WIDTH = 1150,
  TOC_WIDTH = 230,
  TOC_MARGIN = 50;

const MainContent = styled.div`
  max-width: ${(props: { maxContentWidth?: string }) =>
    props.maxContentWidth === undefined
      ? DEFAULT_MAX_CONTENT_WIDTH - TOC_WIDTH - TOC_MARGIN + "px"
      : props.maxContentWidth};
  width: calc(100% - ${TOC_WIDTH}px - ${TOC_MARGIN}px - 50px);
  @media (max-width: 1000px) {
    width: calc(100% - 50px);
  }
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  vertical-align: top;
  display: inline-block;
  padding-top: 50px;
`;

const Toc = styled.div`
  width: ${TOC_WIDTH}px;
  margin-top: 33px;
  margin-bottom: 8px;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 1px;
  color: ${color.gray10};
  text-align: left;
  vertical-align: top;
  display: inline-block;
  position: sticky;
  top: 25px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const TocSection = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 20px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  line-height: 18px;
  color: ${color.gray10};
  transition-duration: 0.3s;
  border-left: 2px solid
    ${(props: { isFocused: boolean }) =>
      props.isFocused ? color.gray13 : color.gray5};

  &:hover {
    cursor: pointer;
  }

  a {
    color: inherit !important;
  }
`;

// NOTE: section should be a Section or SubSection.
function TocText(props: { sec: any }) {
  /* support for emojis and title aliases */
  return (
    <>
      {props.sec.props.emoji ? (
        <div
          css={css`
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          `}
        >
          <Emoji emoji={props.sec.props.emoji} size={16} />
        </div>
      ) : (
        <></>
      )}

      {props.sec.props.tocAlias
        ? props.sec.props.tocAlias
        : props.sec.props.sectionTitle}
    </>
  );
}

/**
 * This brings both the header, page content, section contents
 * in a way that they are clickable.
 *
 * @param props.title is the name of the blog post.
 * @param props.children is a sequence if Section elements.
 */
export default class BlogWrapper extends React.Component<
  {
    title?: string;
    subtitle?: string | React.ReactNode;
    children: any;
    description: string;
    keywords: string;
    metaImage?: string;
    navigationMaxWidth?: string;
    includeHeader?: boolean;
    includeFooter?: boolean;
    maxContentWidth?: string;
  },
  {
    headerIdLocations: number[][];
    focusedSectionI: number;
    focusedSubSectionI: number;
  }
> {
  updateSidebarScroll: { (): void };
  updateHeaderLocations: { (): void };

  // This should be React.ReactNode, but I'm getting linting warnings..
  sections: any[][] = [];

  constructor(props) {
    super(props);
    this.state = {
      focusedSectionI: -1,
      focusedSubSectionI: -1,
      headerIdLocations: [],
    };

    // workaround so that this.state can be accessed within event listeners.
    const self = this;
    this.updateSidebarScroll = () => {
      if (self.state.headerIdLocations.length === 0) {
        return;
      }

      // scrolled to absolute bottom of page, set last section
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        const lastSection = self.state.headerIdLocations.length - 1;

        // set the last section and subsection
        self.setState({
          focusedSectionI: lastSection,
          focusedSubSectionI: self.sections[lastSection].length - 1,
        });
      } else {
        // updates which section should be highlighted.
        let scrollTop =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : ((document.documentElement ||
                document.body.parentNode ||
                document.body) as HTMLElement).scrollTop;

        // give some extra padding to the sections
        scrollTop += 50;

        // above the first section
        if (scrollTop < self.state.headerIdLocations[0][0]) {
          self.setState({
            focusedSectionI: -1,
            focusedSubSectionI: -1,
          });
          return;
        }

        // find which section
        for (let i = self.state.headerIdLocations.length - 1; i >= 0; i--) {
          if (scrollTop > self.state.headerIdLocations[i][0]) {
            self.setState({ focusedSectionI: i });

            // has subsections
            if (self.state.headerIdLocations[i].length > 1) {
              // haven't reached first subsection
              if (scrollTop < self.state.headerIdLocations[i][1]) {
                self.setState({ focusedSubSectionI: -1 });
                break;
              }

              // find which subsection
              for (
                let j = self.state.headerIdLocations[i].length - 1;
                j >= 1;
                j--
              ) {
                if (scrollTop > self.state.headerIdLocations[i][j]) {
                  self.setState({ focusedSubSectionI: j - 1 });
                  break;
                }
              }
            }
            break;
          }
        }
      }
    };

    // another event listener workaround that uses self. :/
    this.updateHeaderLocations = () => {
      // Set the headerId locations fro each section
      let headerIdLocations = [];
      for (const sectionGroup of self.sections) {
        // add the section
        let addLocation = [
          self.getSectionLocation(sectionGroup[0].props.sectionTitle),
        ];

        // add the subsections
        for (const subSection of sectionGroup.slice(1)) {
          addLocation.push(
            self.getSectionLocation(
              subSection.props.sectionTitle,
              PREPEND_TO_SUB_HASH
            )
          );
        }

        headerIdLocations.push(addLocation);
      }

      self.setState({ headerIdLocations: headerIdLocations });
      self.updateSidebarScroll();
    };

    // Let's get the sections for the toc.
    this.sections = [];
    for (const possibleSection of this.props.children) {
      if (
        typeof possibleSection === "object" &&
        possibleSection !== null &&
        Object.keys(possibleSection.props).includes("sectionTitle")
      ) {
        let addToToc: React.ReactNode[] = [possibleSection];

        // let's check if there are any subsections...
        let children = possibleSection.props.children;
        if (!Array.isArray(possibleSection.props.children)) {
          children = [children];
        }
        for (const possibleSubSection of children) {
          if (
            typeof possibleSubSection === "object" &&
            possibleSubSection !== null &&
            Object.keys(possibleSubSection.props).includes("sectionTitle")
          ) {
            addToToc.push(possibleSubSection);
          }
        }

        this.sections.push(addToToc);
      }
    }
  }

  getSectionLocation(sectionTitle: string, prependedToHash: string = "") {
    return (
      document
        .getElementById(prependedToHash + headerId(sectionTitle))
        .getBoundingClientRect().top + window.scrollY
    );
  }

  componentDidMount() {
    this.updateHeaderLocations();
    window.addEventListener("resize", this.updateHeaderLocations);
    window.addEventListener("scroll", this.updateSidebarScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateHeaderLocations);
    window.removeEventListener("scroll", this.updateSidebarScroll);
  }

  render() {
    return (
      <>
        {/* page title -- defaults to including it. */}
        {this.props.includeHeader === false ? (
          <></>
        ) : (
          <TextHeader
            title={this.props.title}
            subtitle={this.props.subtitle}
            navigationMaxWidth={this.props.navigationMaxWidth}
            metaImage={this.props.metaImage}
            description={this.props.description}
            keywords={this.props.keywords}
          />
        )}

        {/* page contents */}
        <div
          css={css`
            text-align: center;
            margin: auto;
            padding-bottom: 100px;
            min-height: 100vh;
          `}
        >
          {/* Main content */}
          <MainContent maxContentWidth={this.props.maxContentWidth}>
            {this.props.children}
          </MainContent>

          {/* Table on contents */}
          <Toc>
            {/* Section is the [0th] index, others are SubSections */}
            {this.sections.map((sectionsContainer: any[], sectionI: number) => (
              <TocSection
                isFocused={this.state.focusedSectionI === sectionI}
                key={sectionI}
              >
                <Link
                  to={"#" + headerId(sectionsContainer[0].props.sectionTitle)}
                >
                  <div key={sectionI}>
                    <TocText sec={sectionsContainer[0]} key={sectionI} />
                    {sectionsContainer.length > 1 ? (
                      <div
                        css={css`
                          float: right;
                          .anticon {
                            font-size: 12px;
                            vertical-align: 0rem;
                            transition-duration: 0.3s;
                            color: ${this.state.focusedSectionI === sectionI
                              ? color.gray10
                              : color.gray6};
                            transform: ${this.state.focusedSectionI === sectionI
                              ? "rotate(0deg)"
                              : "rotate(-90deg)"};
                          }
                        `}
                      >
                        <DownOutlined />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Link>

                {/* add in the SubSections */}
                {this.state.focusedSectionI === sectionI ? (
                  sectionsContainer
                    .slice(1)
                    .map((subSection: any, subSectionI: number) => (
                      <Link
                        to={
                          "#" +
                          PREPEND_TO_SUB_HASH +
                          headerId(subSection.props.sectionTitle)
                        }
                        key={subSectionI}
                      >
                        <TocSection
                          isFocused={
                            this.state.focusedSubSectionI === subSectionI
                          }
                          css={css`
                            text-indent: -11px;
                            padding-left: 42px;
                          `}
                        >
                          <TocText sec={subSection} />
                        </TocSection>
                      </Link>
                    ))
                ) : (
                  <></>
                )}
              </TocSection>
            ))}
          </Toc>
        </div>
        {/* defaults to true */}
        {this.props.includeFooter === false ? <></> : <Footer />}
      </>
    );
  }
}
